import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Select, Button, Layout, Table, Modal, DatePicker } from 'antd';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import 'antd/dist/reset.css'; // Importa los estilos de Ant Design


const { Header, Content, Footer } = Layout;
const { Option } = Select;

const ConsultarkilometragesGeneral = () => {
  const token = localStorage.getItem('token');
  
  const [ajustadores, setAjustadores] = useState([]);
  const [kilometrajes, setKilometrajes] = useState([]);
  const [form] = Form.useForm();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  // Obtiene la lista de ajustadores al cargar el componente
  useEffect(() => {
    axios.get('https://srasdavidback.x10.mx/Ajustadores/ConsultarAjustadores', {
        headers: {
            'Authorization': token,
            'Content-Type': 'multipart/form-data',
          },
    })
      .then(response => {
        setAjustadores(response.data);
      })
      .catch(error => {
        console.error('Error fetching ajustadores:', error);
      });
  }, [token]);

  const onSearch = () => {
    form.validateFields().then(values => {
      const { nombreAjustador, Fecha_Inicio, Fecha_Fin } = values;

      const fechaInicio = Fecha_Inicio ? Fecha_Inicio.format('YYYY-MM-DD') : '';
      const fechaFin = Fecha_Fin ? Fecha_Fin.format('YYYY-MM-DD') : '';

      if (!nombreAjustador) {
        console.error('El nombre del ajustador es requerido');
        return;
      }

      axios.get(`https://srasdavidback.x10.mx/Kilometrajes/ConsultarKilometrajesPorAjustadoryFecha/${nombreAjustador}/${fechaInicio}/${fechaFin}`, {
        headers: {
            'Authorization': token,
          },
      })
        .then(response => {
          if (Array.isArray(response.data.data)) {
            setKilometrajes(response.data.data);
          } else {
            console.error('Los datos recibidos no son un array:', response.data.data);
          }
        })
        .catch(error => {
          console.error('Error fetching registros:', error);
        });
    }).catch(error => {
      console.error('Error en la validación del formulario:', error);
    });
  };

  const handleImageClick = (imagen) => {
    setPreviewImage(`https://srasdavidback.x10.mx/images/${imagen}`);
    setPreviewVisible(true);
  };

  const handleCancel = () => setPreviewVisible(false);

  const exportToExcel = () => {
    if (kilometrajes.length === 0) {
      console.error('No hay datos para exportar');
      return;
    }

    const ws = XLSX.utils.json_to_sheet(kilometrajes);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Kilometrajes');
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'kilometrajes.xlsx');
  };

  // Definir columnas para la tabla
  const columns = [
    {
      title: 'Kilometraje registrado',
      dataIndex: 'KilometrajeRegistrado',
      key: 'KilometrajeRegistrado',
    },
    {
      title: 'Fecha de registro',
      dataIndex: 'FechaDeRegistro',
      key: 'FechaDeRegistro',
    },
    {
      title: 'Ajustador',
      dataIndex: 'AjustadorQueRegistra',
      key: 'AjustadorQueRegistra',
    },
    {
      title: 'Foto de evidencia',
      dataIndex: 'Foto_Evidencia',
      key: 'Foto_Evidencia',
      render: (imagen) => (
        <img
          src={`https://srasdavidback.x10.mx/images/${imagen}`}
          alt="Imagen de perfil"
          onClick={() => handleImageClick(imagen)}
          style={{ width: '100%', maxWidth: 100, borderRadius: '30px', cursor: 'pointer' }}
        />
      ),
    },
  ];

  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#19E092' }}>
      <Header style={{ backgroundColor: 'black', padding: 0, borderRadius: '20px' }}>
        <div style={{ color: 'white', fontSize: '18px', textAlign: 'center' }}>Consulta de kilometrajes</div>
      </Header>
      <div className="circle-background" style={{ position: 'absolute', marginTop: '-50px', marginLeft: '10px' }}></div>
      <div style={{textAlign: 'center', marginTop: '2rem', marginBottom: '2rem'}}>
        <img src="https://cdn.icon-icons.com/icons2/1483/PNG/512/internetspeed_102161.png" alt="Icon"
        style={{maxWidth: '130px'}}></img>
      </div>
      <Content style={{ padding: '20px' }}>
        <Form
          form={form}
          layout="vertical"
          style={{ maxWidth: '600px', margin: '0 auto', backgroundColor: '#ffffff', padding: '20px', borderRadius: '8px' }}
        >
          <Form.Item
            name="nombreAjustador"
            label="Nombre del Ajustador"
            rules={[{ required: true, message: 'Por favor, selecciona un ajustador' }]}
          >
            <Select placeholder="Selecciona un ajustador" allowClear>
              {ajustadores.map(ajustador => (
                <Option key={ajustador.id} value={`${ajustador.Nombres} ${ajustador.Apellidos}`}>
                  {`${ajustador.Nombres} ${ajustador.Apellidos}`}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="Fecha_Inicio" 
            label="Fecha de Inicio de busqueda" 
            rules={[{ required: true, message: 'Por favor selecciona la fecha de ocurrencia' }]}>
              <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="Fecha_Fin" 
            label="Fecha fin de busqueda" 
            rules={[{ required: true, message: 'Por favor selecciona la fecha de ocurrencia' }]}>
              <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={onSearch}>Buscar</Button>
          </Form.Item>
        </Form>

        <Button 
          type="default" 
          onClick={exportToExcel}
          disabled={kilometrajes.length === 0} // Deshabilitar el botón si no hay datos
          style={{ marginTop: '20px' }}
        >
          Exportar a Excel
        </Button>
      <div style={{ overflowX: 'auto',  width: '100%', textAlign: 'center'}}>
        <Table
          dataSource={kilometrajes}
          columns={columns}
          rowKey="id"
          style={{ marginTop: '20px' }}
        />
      </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>David Isaac ©2024</Footer>

      {/* Modal para vista previa de imagen */}
      <Modal
        visible={previewVisible}
        footer={null}
        onCancel={handleCancel}
        width={800}
      >
        <img
          alt="Preview"
          style={{ width: '100%' }}
          src={previewImage}
        />
      </Modal>
    </Layout>
  );
};

export default ConsultarkilometragesGeneral;
