import React, { useState } from 'react';
import { Form, Input, DatePicker, Button, message, Row, Col, Layout, Upload} from 'antd';
import axios from 'axios';
import { UploadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';


const { Header } = Layout;

const RegistrarFraude = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const nombres = localStorage.getItem('nombre');
    const token = localStorage.getItem('token');
    const [pdfFile, setPdfFile] = useState(null);



    const pdfUploadProps = {
        beforeUpload: (file) => {
            setPdfFile(file); // Set the PDF file to state
            return false;  // Prevent upload to server
        },
        fileList: pdfFile ? [pdfFile] : [],
    };

    const onFinish = async (values) => {
        setLoading(true);
        try {
            // Create a FormData instance
            const formData = new FormData();

            // Add form values to FormData
            Object.keys(values).forEach(key => {
                if (values[key] !== undefined && values[key] !== null) {
                    // Format date values
                    if (key === 'FechaDeAtencion' || key === 'FechaDeAtencion') {
                        formData.append(key, values[key].format('YYYY-MM-DD'));
                    } else {
                        formData.append(key, values[key]);
                    }
                }
            });

            // Add other fields
            formData.append('AjustadorQueRegistra', nombres);

            // Add PDF file to FormData
            if (pdfFile) {
                formData.append('PDF', pdfFile);
            }


            formData.append('AjustadorQueRegistra', nombres);

            const { Numero_Siniestro: Numero_Siniestro } = values;

            const responserepetido = await fetch(`https://srasdavidback.x10.mx/ValidarRegistro/ValidarRegistrosRepetidos/${Numero_Siniestro}`, {
                headers: {
                    'Authorization': token
                }
            });
            const data = await responserepetido.json(); 
            console.log("respuesta del back siniestros" + data);


            if(data.bol === true){
                console.log("Indicador de existencia" + responserepetido.data.bol);
                message.error('El Siniestros ya se encuentra registrado');

            }else{
            // Send POST request to server
            const response = await axios.post('https://srasdavidback.x10.mx/Fraudes/RegistrarSiniestro', formData, {
                headers: {
                    'Authorization': token,
                    'Content-Type': 'multipart/form-data',
                }
            });

            if (response.status === 200) {
                message.success('Siniestro registrado correctamente');
                navigate("/PrincipalAjustador");
            } else {
                message.error('Error al registrar el siniestro');
            }

        }
        } catch (error) {
            console.error('Error al registrar el siniestro:', error);
            message.error('Error al registrar el siniestro');
        }
        setLoading(false);
    };


    return (
        <div style={{ backgroundColor: '#134B70', padding: '20px', borderRadius: '8px', textAlign: 'center' }}>
                <div>
                    <Header style={{ backgroundColor: 'black', textAlign: 'center', paddingBottom: '2rem', borderRadius: '10px', marginBottom: '1rem', width: '100%' }}>
                        <h1 className="title">Registrar fraude</h1>
                    </Header>
                    <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', marginTop: '3rem', marginBottom: '2rem' }}>
                        <img src="https://cdn-icons-png.freepik.com/512/10297/10297337.png" style={{ maxHeight: '200px', filter: "drop-shadow(-2px 2px 15px rgba(0, 0, 0, 0.7))" }} alt="Imagen de registro de siniestro" />
                    </div>
                    <div className="circle-background" style={{ position: 'absolute', marginTop: '-90px', marginLeft: '-25px' }}></div>
                    <div style={{ backgroundColor: '#508C9B', borderRadius: '20px', padding: '1rem', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                        <Form layout="vertical" onFinish={onFinish}>
                            <Row gutter={16}>
                                <Col xs={24} md={12}>
                                    <Form.Item 
                                    name="Numero_Siniestro" 
                                    label="Número de Siniestro" 
                                    rules={[{ required: true, message: 'Por favor ingresa el número de siniestro' },
                                        {pattern: /^[0-9]+$/, message: "El folio solo debe tener numeros"},
                                        {len: 15, message: "la longitud es incorrecta"}
                                    ]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Row gutter={16}>
                                <Col xs={24}>
                                    <Form.Item label="PDF" name="PDF">
                                        <Upload {...pdfUploadProps}>
                                            <Button icon={<UploadOutlined />}>Seleccionar PDF</Button>
                                        </Upload>
                                    </Form.Item>
                                </Col>
                            </Row>
                                <Col xs={24} md={12}>
                                    <Form.Item name="Comentario" 
                                    label="Comentario sobre el fraude" 
                                    rules={[{ required: true, message: 'Por favor ingresa el folio EDUA' },
                                        {max: 50, message: "La longitud es incorrecta"},
                                    ]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={12}>
                                    <Form.Item name="FechaDeAtencion" 
                                    label="Fecha de Atención" 
                                    rules={[{ required: true, message: 'Por favor selecciona la fecha de ocurrencia' }]}>
                                        <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={12}>
                                    <Form.Item name="MontoDeValuacion" 
                                    label="Monto de valuación del fraude" 
                                    rules={[{ required: true, message: 'Por favor ingresa el número de póliza' },
                                        {max: 5, message: "La longitud es incorrecta"},
                                        {pattern: /^[0-9]+$/, message: "El monto no puede contener letras"}
                                    ]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item name="Placa" 
                                    label="Placa del vehiculo involucrado" 
                                    rules={[{ required: true, message: 'Por favor ingresa la serie VIN' },
                                        {min: 6, message: "La longitud es incorrecta"},
                                        {max: 7, message: "Longitud incorrecta"}
                                    ]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={12}>
                                    <Form.Item name="NombreDelConductor" 
                                    label="Nombre del conducto o cliente" 
                                    rules={[{ required: true, message: 'Por favor ingresa el nombre del conductor' },
                                        {min: 6, message: "la longitud es incorrecta"},
                                        {max: 40, message: "la longitud es incorrecta"},
                                    ]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24}>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" loading={loading} style={{ width: '100%', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}>
                                            Registrar Siniestro
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>

            <br />
            <br />
            <br />
        </div>
    );
};

export default RegistrarFraude;
