import React, { useState } from 'react';
import { Form, Input, DatePicker, Button, message, Row, Col, Layout, Select} from 'antd';
import axios from 'axios';
import citiesByState from '../Resources/citiesByState.json';
import { useNavigate } from 'react-router-dom';

const { Header } = Layout;

const RegistrarSiniestroNoLocalizado = () => {
    const navigate = useNavigate();

    const { Option } = Select;

    const [cities, setCities] = useState([]);
    const [loading, setLoading] = useState(false);
    const nombres = localStorage.getItem('nombre');
    const token = localStorage.getItem('token');


    const onFinish = async (values) => {
        setLoading(true);
        try {
            // Create a FormData instance
            const formData = new FormData();

            // Add form values to FormData
            Object.keys(values).forEach(key => {
                if (values[key] !== undefined && values[key] !== null) {
                    // Format date values
                    if (key === 'FechaDeOcurrido' || key === 'FechaDeAtencion') {
                        formData.append(key, values[key].format('YYYY-MM-DD'));
                    } else {
                        formData.append(key, values[key]);
                    }
                }
            });

            // Add other fields
            formData.append('AjustadorQueRegistra', nombres);

            const { Numero_Siniestro: Numero_Siniestro } = values;

            const responserepetido = await fetch(`https://srasdavidback.x10.mx/ValidarRegistro/ValidarRegistrosRepetidos/${Numero_Siniestro}`, {
                headers: {
                    'Authorization': token
                }
            });
            const data = await responserepetido.json(); 
            console.log("respuesta del back siniestros" + data);


            if(data.bol === true){
                console.log("Indicador de existencia" + responserepetido.data.bol);
                message.error('El Siniestros ya se encuentra registrado');

            }else{
            // Send POST request to server
            const response = await axios.post('https://srasdavidback.x10.mx/SiniestrosNoLocalizados/RegistrarSiniestro', formData, {
                headers: {
                    'Authorization': token,
                    'Content-Type': 'multipart/form-data',
                }
            });

            if (response.status === 200) {
                navigate("/PrincipalAjustador");
                message.success('Siniestro registrado correctamente');
            } else {
                message.error('Error al registrar el siniestro');
            }

        }
        
        } catch (error) {
            console.error('Error al registrar el siniestro:', error);
            message.error('Error al registrar el siniestro');
        }
        setLoading(false);
    };
    const [form] = Form.useForm();

    const handleStateChange = (value) => {
        const selectedCities = citiesByState[value] || [];
        setCities(selectedCities);
        form.setFieldsValue({ Ciudad: undefined });
      };

    return (
        <div style={{ backgroundColor: '#C738BD', padding: '20px', borderRadius: '8px', textAlign: 'center' }}>
                <div>
                    <Header style={{ backgroundColor: 'black', textAlign: 'center', paddingBottom: '2rem', borderRadius: '10px', marginBottom: '1rem', width: '100%' }}>
                        <h1 className="title">Registrar siniestros no localizados</h1>
                    </Header>
                    <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', marginTop: '3rem', marginBottom: '2rem' }}>
                        <img src="https://cdn-icons-png.flaticon.com/256/2806/2806966.png" style={{ maxHeight: '200px', filter: "drop-shadow(-2px 2px 15px rgba(0, 0, 0, 0.7))" }} alt="Imagen de registro de siniestro" />
                    </div>
                    <div className="circle-background" style={{ position: 'absolute', marginTop: '-90px', marginLeft: '-25px' }}></div>
                    <div style={{ backgroundColor: '#E49BFF', borderRadius: '20px', padding: '1rem', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                        <Form layout="vertical" onFinish={onFinish}>
                            <Row gutter={16}>
                                <Col xs={24} md={12}>
                                    <Form.Item name="FechaDeOcurrido" 
                                    label="Fecha de Ocurrido" 
                                    rules={[{ required: true, message: 'Por favor selecciona la fecha de ocurrencia' }]}>
                                        <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item name="FechaDeAtencion" 
                                    label="Fecha de Atención" 
                                    rules={[{ required: true, message: 'Por favor selecciona la fecha de atención' }]}>
                                        <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={12}>
                                    <Form.Item name="Numero_Siniestro" 
                                    label="Número de Siniestro" 
                                    rules={[{ required: true, message: 'Por favor ingresa el número de póliza' },
                                        {len: 15, message: "La longitud es incorrecta"},
                                        {pattern: /^[0-9]+$/, message: "El folio solo debe tener numeros"}
                                    ]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="Estado"
                                        label="Estado"
                                        rules={[{ required: true, message: 'Por favor ingresa el estado' }]}
                                    >
                                        <Select onChange={handleStateChange} placeholder="Selecciona un estado">
                                        {Object.keys(citiesByState).map((state) => (
                                            <Option key={state} value={state}>
                                            {state}
                                            </Option>
                                        ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24}>
                                    <Form.Item
                                        name="Ciudad"
                                        label="Ciudad"
                                        rules={[{ required: true, message: 'Por favor selecciona una ciudad' }]}
                                    >
                                        <Select placeholder="Selecciona una ciudad" disabled={cities.length === 0}>
                                        {cities.map((city) => (
                                            <Option key={city} value={city}>
                                            {city}
                                            </Option>
                                        ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item name="Comentario" 
                                    label="Razón por la que no se localizo el siniestro" 
                                    rules={[{ required: true, message: 'Por favor ingresa el número de póliza' },
                                        {max: 100, message: "La longitud es incorrecta"},
                                    ]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24}>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" loading={loading} style={{ width: '100%', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}>
                                            Registrar Siniestro
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>

            <br />
            <br />
            <br />
        </div>
    );
};

export default RegistrarSiniestroNoLocalizado;
