import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Upload, message, DatePicker, Layout, Table } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import '../../Styles/RegistrarKilometraje.css';

// const { Option } = Select;

const RegistrarKilometraje = () => {
  const { Header } = Layout;

  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [formData, setFormData] = useState({});
  const [kilometrajes, setKilometrajes] = useState([]);
  const [lastKilometraje, setLastKilometraje] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(3);

  const onFinish = async (values) => {
    try {
      if (lastKilometraje !== null && values.KilometrajeRegistrado <= lastKilometraje) {
        message.error('El kilometraje registrado debe ser mayor al último ingresado.');
        return;
      }

      const nombres = localStorage.getItem('nombre');
      const token = localStorage.getItem('token');

      if (!nombres) {
        message.error('El nombre del ajustador no está disponible');
        return;
      }

      if (!token) {
        message.error('El token de autenticación no está disponible');
        return;
      }

      const completeFormData = { ...formData, ...values, AjustadorQueRegistra: nombres };

      const submitData = new FormData();
      submitData.append('KilometrajeRegistrado', completeFormData.KilometrajeRegistrado);
      submitData.append('FechaDeRegistro', completeFormData.FechaDeRegistro.format('YYYY-MM-DD'));
      submitData.append('Foto_Evidencia', completeFormData.Foto_Evidencia[0].originFileObj);
      submitData.append('AjustadorQueRegistra', nombres);

      const response = await axios.post('https://srasdavidback.x10.mx/Kilometrajes/RegistrarKilometraje', submitData, {
        headers: {
          'Authorization': token,
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 200) {
        message.success('Kilometraje registrado exitosamente');
        form.resetFields();
        setFileList([]);
        setFormData({});
        fetchKilometrajes(); // Refrescar la tabla después de registrar
      } else {
        throw new Error('Error en el servidor');
      }
    } catch (error) {
      console.error('Error:', error.message);
      message.error('Hubo un error al procesar la solicitud');
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const uploadProps = {
    onRemove: (file) => {
      setFileList((prevList) => prevList.filter((item) => item.uid !== file.uid));
    },
    beforeUpload: (file) => {
      setFileList((prevList) => [...prevList, file]);
      return false;
    },
    fileList,
  };

  const fetchKilometrajes = async () => {
    try {
      const nombres = localStorage.getItem('nombre');
      const token = localStorage.getItem('token');

      if (!nombres) {
        message.error('El nombre del ajustador no está disponible');
        return;
      }

      if (!token) {
        message.error('El token de autenticación no está disponible');
        return;
      }

      const response = await axios.get(`https://srasdavidback.x10.mx/Kilometrajes/ConsultarKilometrajesPorAjustador/${nombres}`, {
        headers: {
          'Authorization': token,
        }
      });

      if (response.status === 200) {
        const { data } = response.data;
        console.log('Datos recibidos:', data);
        const sortedData = Array.isArray(data) ? data.sort((a, b) => new Date(b.FechaDeRegistro) - new Date(a.FechaDeRegistro)) : [];
        setKilometrajes(sortedData);
        if (sortedData.length > 0) {
          setLastKilometraje(Math.max(...sortedData.map(item => item.KilometrajeRegistrado)));
        } else {
          setLastKilometraje(null);
        }
      } else {
        throw new Error('Error en el servidor');
      }
    } catch (error) {
      console.error('Error:', error.message);
      message.error('Hubo un error al obtener los kilometrajes');
    }
  };

  useEffect(() => {
    fetchKilometrajes();
  }, []);

  const columns = [
    {
      title: 'Kilometraje Registrado',
      dataIndex: 'KilometrajeRegistrado',
      key: 'KilometrajeRegistrado',
    },
    {
      title: 'Fecha de Registro',
      dataIndex: 'FechaDeRegistro',
      key: 'FechaDeRegistro',
    },
    {
      title: 'Ajustador que Registra',
      dataIndex: 'AjustadorQueRegistra',
      key: 'AjustadorQueRegistra',
    },
  ];

  return (
    <div>
      <Header className="header" style={{ backgroundColor: 'black', textAlign: 'center', paddingBottom: '2rem', borderRadius: '10px', marginBottom: '1rem' }}>
        <h1 className="title">Registro de kilometrajes</h1>
      </Header>
      <div className="container">
        <div className="circle-background" style={{ position: 'absolute', marginTop: '-90px', marginLeft: '-25px' }}></div>
        <div className="circle-background" style={{ position: 'absolute', marginTop: '200px', marginLeft: '1200px' }}></div>
        <div className="form-container">
        <br />
          <Form
            form={form}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            style={{ padding: '8px', borderRadius: '20px' }}
          >
            <img className="ImgRegisterUser" src="https://i.pinimg.com/originals/9e/36/55/9e365506aaaa49f84fa6e1c20733a47a.png" alt="Icono Register" 
            style={{maxHeight: "100px"}}/>
            <Form.Item
              name="KilometrajeRegistrado"
              label="Kilometraje Registrado"
              rules={[{ required: true, message: 'Por favor ingrese el kilometraje' }]}
            >
              <Input type="number" placeholder="Ingrese el kilometraje" />
            </Form.Item>

            <Form.Item
              name="FechaDeRegistro"
              label="Fecha de Registro"
              rules={[{ required: true, message: 'Por favor seleccione la fecha' }]}
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
              label="Foto de evidencia"
              name="Foto_Evidencia"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[{ required: true, message: 'Por favor sube una foto de evidencia!' }]}
            >
              <Upload {...uploadProps}>
                <Button icon={<UploadOutlined />}>Seleccionar Archivo</Button>
              </Upload>
            </Form.Item>
            {/* <Form.Item
                name="IndicadorCambioDeVehiculo"
                label="¿Se camio de vehiculo?"
                rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
                >
                  <Select>
                    <Option value="Si">Si</Option>
                      <Option value="No">No</Option>
                  </Select>
            </Form.Item> */}
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ backgroundColor: '#35DC21' }}>
                Registrar
              </Button>
            </Form.Item>
          </Form>
          <Table
            columns={columns}
            dataSource={kilometrajes.slice((currentPage - 1) * pageSize, currentPage * pageSize)}
            rowKey="id"
            style={{ marginTop: '2rem' }}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              total: kilometrajes.length,
              onChange: (page) => setCurrentPage(page),
              showSizeChanger: false
            }}
          />
        </div>
        <br />
        <br />
        <br />
        <br />

      </div>
    </div>
  );
};

export default RegistrarKilometraje;
