import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Input, Button, DatePicker, Row, Col, Card, Select } from 'antd';
import { PieChart, Pie, Cell, Tooltip, Legend, BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import GenerarPDF from './GenerarPDF'; // Importa el componente
import '../../Styles/Indicadores.css';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
const { Option } = Select;

const Indicadores = () => {
  const token = localStorage.getItem('token');

  const [ajustadores, setAjustadores] = useState([]);

  useEffect(() => {
    axios.get('https://srasdavidback.x10.mx/Ajustadores/ConsultarAjustadores', {
        headers: {
            'Authorization': token,
            'Content-Type': 'multipart/form-data',
          },
    })
      .then(response => {
        setAjustadores(response.data);
      })
      .catch(error => {
        console.error('Error fetching ajustadores:', error);
      });
  }, [token]);


  const [siniestros, setSiniestros] = useState([]);
  const [polizasEspeciales, setPolizasEspeciales] = useState([]);
  console.log(siniestros);
  console.log(polizasEspeciales);

  const [nomAjustador, setNombreAjustador] = useState('');

  const [counts, setCounts] = useState({
    cancelados: 0,
    noLocalizados: 0,
    siniestros: 0,
    polizasEspeciales: 0,
    SipacsEntregados: 0,
    SipacsRecibidos: 0,
    SipacsEntregadosPol: 0,
    SipacsRecibidosPol: 0,
    fraudesMontoTotal: 0
  });
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setLoading(true);
    const ajustador = values.ajustador;
    const fecha_inicio = values.fecha_inicio.format('YYYY-MM-DD');
    const fecha_fin = values.fecha_fin.format('YYYY-MM-DD');

    try {

      const token = localStorage.getItem('token');

      const siniestrosResponse = await axios.get(`https://srasdavidback.x10.mx/Siniestros/ConsultarSiniestroPorAjustadoryPeriodo/${ajustador}/${fecha_inicio}/${fecha_fin}`, { headers: { Authorization: token } });
      const polizasEspecialesResponse = await axios.get(`https://srasdavidback.x10.mx/PolizasEspeciales/ConsultarPolizasEspecialesPorPeriodo/${ajustador}/${fecha_inicio}/${fecha_fin}`, { headers: { Authorization: token } });
      
      setSiniestros(siniestrosResponse.data);
      setPolizasEspeciales(polizasEspecialesResponse.data);
      
      const resultado_entregado = "Entrega_Sipac";
      const resultado_recibido = "Recibe_Sipac";
      const indicadorSi = "Si";
      const indicadorNo = "No";
      const [canceladosRes, noLocalizadosRes, siniestrosRes, polizasEspecialesRes, EntregadosRes, RecibidosRes, EntregadosResPol, RecibidosResPol, fraudesRes, arriboSisiniestros, arriboNosiniestros, arriboSipolizas, arriboNopolizas] = await Promise.all([
        axios.get(`https://srasdavidback.x10.mx/SiniestrosCancelados/ConsultarSiniestrosCanceladosPorPeriodo/${ajustador}/${fecha_inicio}/${fecha_fin}`, { headers: { Authorization: token } }),
        axios.get(`https://srasdavidback.x10.mx/SiniestrosNoLocalizados/ConsultarSiniestrosNoLocalizadosPorPeriodo/${ajustador}/${fecha_inicio}/${fecha_fin}`, { headers: { Authorization: token } }),
        axios.get(`https://srasdavidback.x10.mx/Siniestros/ConsultarSiniestroPorAjustadoryPeriodo/${ajustador}/${fecha_inicio}/${fecha_fin}`, { headers: { Authorization: token } }),
        axios.get(`https://srasdavidback.x10.mx/PolizasEspeciales/ConsultarPolizasEspecialesPorPeriodo/${ajustador}/${fecha_inicio}/${fecha_fin}`, { headers: { Authorization: token } }),
        axios.get(`https://srasdavidback.x10.mx/ResultadosTercerosInvolucradosExtendidos/ConsultarSipacsEntregadosyRecibidos/${ajustador}/${fecha_inicio}/${fecha_fin}/${resultado_entregado}`, { headers: { Authorization: token } }),
        axios.get(`https://srasdavidback.x10.mx/ResultadosTercerosInvolucradosExtendidos/ConsultarSipacsEntregadosyRecibidos/${ajustador}/${fecha_inicio}/${fecha_fin}/${resultado_recibido}`, { headers: { Authorization: token } }),
        axios.get(`https://srasdavidback.x10.mx/ResultadosTercerosInvolucradosExtendidosPolizas/ConsultarSipacsEntregadosyRecibidos/${ajustador}/${fecha_inicio}/${fecha_fin}/${resultado_entregado}`, { headers: { Authorization: token } }),
        axios.get(`https://srasdavidback.x10.mx/ResultadosTercerosInvolucradosExtendidosPolizas/ConsultarSipacsEntregadosyRecibidos/${ajustador}/${fecha_inicio}/${fecha_fin}/${resultado_recibido}`, { headers: { Authorization: token } }),
        axios.get(`https://srasdavidback.x10.mx/Fraudes/ConsultarFraudesPorPeriodo/${ajustador}/${fecha_inicio}/${fecha_fin}`, { headers: { Authorization: token } }),
        //CONSULTAR ARRIBOS DONDE SE LELGO PRIMERO EN POLIZAS Y SINIESTROS
        axios.get(`https://srasdavidback.x10.mx/TercerosInvolucradosSiniestrosNormales/ConsultarIndicadorArriboSiniestros/${ajustador}/${fecha_inicio}/${fecha_fin}/${indicadorSi}`, { headers: { Authorization: token } }),
        axios.get(`https://srasdavidback.x10.mx/TercerosInvolucradosPoliza/ConsultarIndicadorArriboSiniestros/${ajustador}/${fecha_inicio}/${fecha_fin}/${indicadorSi}`, { headers: { Authorization: token } }),
        //CONSULTAR ARRIBOS DONDE SE LELGO PRIMERO EN POLIZAS Y SINIESTROS
        axios.get(`https://srasdavidback.x10.mx/TercerosInvolucradosSiniestrosNormales/ConsultarIndicadorArriboSiniestros/${ajustador}/${fecha_inicio}/${fecha_fin}/${indicadorNo}`, { headers: { Authorization: token } }),
        axios.get(`https://srasdavidback.x10.mx/TercerosInvolucradosPoliza/ConsultarIndicadorArriboSiniestros/${ajustador}/${fecha_inicio}/${fecha_fin}/${indicadorNo}`, { headers: { Authorization: token } })
    ]);


    setNombreAjustador(ajustador); // Asigna el nombre del ajustador


      // Suma los valores de la columna 'monto'
      const fraudesMontoTotal = fraudesRes.data?.data?.reduce((total, fraude) => total + fraude.MontoDeValuacion, 0) || 0;
      const arribosprimerogeneral = arriboSisiniestros.data?.data?.length + arriboSipolizas.data?.data?.length;
      const arribosdespuesgeneral = arriboNosiniestros.data?.data?.length + arriboNopolizas.data?.data?.length;
      const totalarribos = arribosprimerogeneral + arribosdespuesgeneral;

      setCounts({
        cancelados: canceladosRes.data?.data?.length || 0,
        noLocalizados: noLocalizadosRes.data?.data?.length || 0,
        siniestros: siniestrosRes.data?.data?.length || 0,
        polizasEspeciales: polizasEspecialesRes.data?.data?.length || 0,
        SipacsEntregados: EntregadosRes.data?.data?.length || 0,
        SipacsRecibidos: RecibidosRes.data?.data?.length || 0,
        SipacsEntregadosPol: EntregadosResPol.data?.data?.length || 0,
        SipacsRecibidosPol: RecibidosResPol.data?.data?.length || 0,
        FraudesConteo: fraudesRes.data?.data?.length || 0,
        fraudesMontoTotal,
        arribosprimerogeneral,
        arribosdespuesgeneral,
        totalarribos
      });
      
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setLoading(false);
    }
  };

  const data = [
    { name: 'Siniestros Cancelados', value: counts.cancelados },
    { name: 'No Localizados', value: counts.noLocalizados },
    { name: 'Siniestros', value: counts.siniestros },
    { name: 'Pólizas Especiales', value: counts.polizasEspeciales }
  ];

  const sipacsData = [
    { name: 'Sipacs Entregados', value: counts.SipacsEntregados + counts.SipacsEntregadosPol },
    { name: 'Sipacs Recibidos', value: counts.SipacsRecibidos + counts.SipacsRecibidosPol }
  ];

  const total_sipacs = counts.SipacsEntregados + counts.SipacsEntregadosPol + counts.SipacsRecibidos + counts.SipacsRecibidosPol; 
  const porcentajedearriboPrimero = ((counts.arribosprimerogeneral) / (counts.totalarribos)) * 100;
  return (
    <div className="indicadores-container">
      <div className="content">
        <h1 style={{ textAlign: 'center', marginBottom: '3rem' }}>Consulta de Indicadores</h1>
        <div style={{ textAlign: 'center' }}>
          <img 
            src="https://cdn-icons-png.flaticon.com/512/6997/6997889.png" 
            alt="Indicadores_ico"
            style={{ maxHeight: '150px', textAlign: 'center', marginBottom: '2rem' }}
          />
        </div>
        <div style={{ border: '1px solid black', borderRadius: '10px', padding: '1rem', marginBottom: '2rem', marginTop: '1rem' }}>
            <p>Ingrese los datos solicitados:</p>
          <Form form={form} layout="inline" onFinish={onFinish} className="search-form" style={{ textAlign: 'center', padding: '1rem'}}>
            {/* <p>Ajustador a consultar *</p> */}
            {/* <Form.Item
              name="ajustador"
              rules={[{ required: true, message: 'Por favor ingrese el nombre del ajustador' }]}
              style={{margin:'1rem'}}
            >
              <Input placeholder="Ajustador" />
            </Form.Item> */}
            <Form.Item
            name="ajustador"
            label="Nombre del Ajustador"
            rules={[{ required: true, message: 'Por favor, selecciona un ajustador' }]}
          >
            <Select placeholder="Selecciona un ajustador" allowClear>
              {ajustadores.map(ajustador => (
                <Option key={ajustador.id} value={`${ajustador.Nombres} ${ajustador.Apellidos}`}>
                  {`${ajustador.Nombres} ${ajustador.Apellidos}`}
                </Option>
              ))}
            </Select>
          </Form.Item>

            <p>Fecha Inicio:</p>
            <Form.Item
              name="fecha_inicio"
              rules={[{ required: true, message: 'Por favor seleccione el rango de fechas' }]}
              style={{margin:'1rem'}}
            >
              <DatePicker format="YYYY-MM-DD" />
            </Form.Item>
            <p>Fecha fin:</p>
            <Form.Item
              name="fecha_fin"
              rules={[{ required: true, message: 'Por favor seleccione el rango de fechas' }]}
              style={{margin:'1rem'}}
            >
              <DatePicker format="YYYY-MM-DD" />
            </Form.Item>
            <Form.Item style={{margin:'1rem'}}>
              <Button type="primary" htmlType="submit" loading={loading}>
                Buscar
              </Button>
            </Form.Item>
          </Form>
        </div>
        <h2 style={{textAlign: 'center', margin: '0.5rem', marginTop:'1rem', marginBottom:'2rem'}}>Indicadores del ajustador</h2>
        <Row gutter={[16, 16]} className="cards-row" style={{border: '1px solid black', borderRadius: '20px', padding: '1rem'}}>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Card title="Siniestros Cancelados">
              {counts.cancelados}
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Card title="No Localizados">
              {counts.noLocalizados}
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Card title="Siniestros">
              {counts.siniestros}
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Card title="Pólizas Especiales">
              {counts.polizasEspeciales}
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Card title="Sipac entregados">
              {counts.SipacsEntregados + counts.SipacsEntregadosPol}
            </Card>
          </Col>          
          <Col xs={24} sm={12} md={12} lg={12}>
            <Card title="Sipacs Recibidos">
              {counts.SipacsRecibidos + counts.SipacsRecibidosPol}
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Card title="Monto Total de Fraudes detectados">
              ${counts.fraudesMontoTotal}
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Card title="Porcentaje de sipacs entregados">
            {((counts.SipacsEntregados + counts.SipacsEntregadosPol)/total_sipacs) * 100} %          
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Card title="Porcentaje de sipacs recibidos">
            {((counts.SipacsRecibidos + counts.SipacsRecibidosPol)/total_sipacs) * 100} %          
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Card title="Total de fraudes detectados">
            {counts.FraudesConteo}          
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Card title="Veces que arribo primero al siniestro">
            {counts.arribosprimerogeneral}          
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Card title="Veces que arribo despues al siniestro">
            {counts.arribosdespuesgeneral}          
            </Card>
          </Col>
        </Row>
        <div className="chart-container" style={{ marginTop: '2rem' }}>
           <PieChart width={400} height={400} style={{padding: '1rem'}}>
             <Pie
              data={data}
              cx={200}
              cy={200}
              labelLine={false}
              label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
              outerRadius={150}
              fill="#8884d8"
              dataKey="value"
            >
                <br></br>
                <br></br>
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
                <br></br>
                <br></br>
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </div>
        <h2 style={{textAlign: 'center', margin:'0.5rem', marginTop: '2rem'}}>Indicadores SIPACS entregados/recibidos</h2>
       <div className="chart-container" style={{ marginTop: '2rem', width: '100%', height: '400px' }}>
          <ResponsiveContainer>
            <BarChart data={sipacsData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="value" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>        
        <GenerarPDF counts={counts} data={data} sipacsData={sipacsData} Ajustador={nomAjustador} total_sipacs = {total_sipacs} porcentajedearriboPrimero = {porcentajedearriboPrimero} siniestros={siniestros}
        polizasEspeciales={polizasEspeciales}/>        
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>
    
  );
};

export default Indicadores;



