// import jsPDF from 'jspdf';
// import { Button } from 'antd';
// import { Icon } from '@iconify/react';
// import filePdfOutline from '@iconify/icons-mdi/file-pdf-outline'; // Ícono de PDF

// const GenerarPDF = ({ counts, Ajustador, total_sipacs, porcentajedearriboPrimero, siniestros, polizasEspeciales}) => {

//   const generarPDF = async () => {
//     const doc = new jsPDF();
//     const imagenpdf = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8aN3lSVnwcW9hSaKjR02EJqUOVg2s2LWOB98nceIDzg&s";

//     // Título del PDF
//     doc.setFontSize(18);
//     doc.text('Reporte de Indicadores', 14, 22);
//     doc.setFontSize(15);
//     doc.text('Nombre:' + Ajustador, 14, 30);
//     // Agregar la información de los counts
//     doc.setFontSize(12);
//     doc.addImage(imagenpdf, 'JPEG', 130, 10, 62, 62);
//     doc.text(`Siniestros Cancelados: ${counts.cancelados}`, 14, 40);
//     doc.text(`No Localizados: ${counts.noLocalizados}`, 14, 50);
//     doc.text(`Siniestros: ${counts.siniestros}`, 14, 60);
//     doc.text(`Pólizas Especiales: ${counts.polizasEspeciales}`, 14, 70);
//     doc.text(`Sipacs Entregados: ${counts.SipacsEntregados + counts.SipacsEntregadosPol}`, 14, 80);
//     doc.text(`Sipacs Recibidos: ${counts.SipacsRecibidos + counts.SipacsRecibidosPol}`, 14, 90);
//     doc.text(`Fraudes detectados: ${counts.FraudesConteo}`, 14, 100);
//     doc.text(`Monto Total de Fraudes detectados: $${counts.fraudesMontoTotal}`, 14, 110);

//     const porcentaje_sipac = ((counts.SipacsEntregados + counts.SipacsEntregadosPol)/total_sipacs) * 100;
//     let BonoSipac;
//     if (porcentaje_sipac<=43){
//       BonoSipac = 1000;
//     }else{
//       BonoSipac = 0;
//     }
//     // Calcular el total
//     const totalCount = counts.siniestros + counts.polizasEspeciales; // Asume que este es el total de registros
//     const EsperadoFraudes = totalCount * 250;
//     const obtenido_fraudes = counts.fraudesMontoTotal;
//     let IndiceBonoFraudes;
//     let prorcentajeBonoFraudes;
//     let bonototal;
    
//     let BonoFraudes;

//     if(obtenido_fraudes >= EsperadoFraudes){
//       BonoFraudes = 2500;
//     }else {
//       IndiceBonoFraudes = (obtenido_fraudes/EsperadoFraudes)*100;
//       prorcentajeBonoFraudes = IndiceBonoFraudes/100;
//       bonototal = prorcentajeBonoFraudes * 2500;
//       BonoFraudes = bonototal;
//     }
//     let BonoDeArribo;
//     if(porcentajedearriboPrimero>80){
//       BonoDeArribo = 1000;
//     }else{
//       BonoDeArribo = 0;  
//     }

//     let total = 0;

//     // Lógica para calcular el total basado en el número de registros
//     if (totalCount <= 55) {
//       total = totalCount * 55;
//     } else {
//       total = (55 * 55) + ((totalCount - 55) * 170);
//     }
//     doc.text('--------------------------------------------------------------------------------------------------------------------------------------------------------------', 1, 115);
//     doc.text(`Total Siniestros y Pólizas (calculado): $ ${total}`, 14, 120);
//     doc.text(`Bono por detección de fraudes: $${BonoFraudes.toFixed(2)}`, 14, 130);
//     doc.text('Bono por responsabilidad de SIPAC:' + BonoSipac.toFixed(), 14, 140);
//     doc.text('Bono de arribo:' + BonoDeArribo, 14, 150);
//     doc.text('--------------------------------------------------------------------------------------------------------------------------------------------------------------', 1, 160);
//     doc.text('Total de incentivos basads en indicadores',14, 170);
//     doc.text('--------------------------------------------------------------------------------------------------------------------------------------------------------------', 1, 175);

//     doc.save('reporte_indicadores.pdf');
//   };

//   return (
//     <Button type="primary" onClick={generarPDF} style={{ margin: '2rem', backgroundColor: 'red'}}>
//       Generar PDF
//       <Icon icon={filePdfOutline} style={{ fontSize: '24px', color: 'white' }} />
//     </Button>
//   );
// };

// export default GenerarPDF;





// import jsPDF from 'jspdf';
// import { Button } from 'antd';
// import { Icon } from '@iconify/react';
// import filePdfOutline from '@iconify/icons-mdi/file-pdf-outline'; // Ícono de PDF

// const GenerarPDF = ({ counts, Ajustador, total_sipacs, porcentajedearriboPrimero, siniestros, polizasEspeciales }) => {

//   const generarPDF = async () => {
//     const doc = new jsPDF();
//     const imagenpdf = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8aN3lSVnwcW9hSaKjR02EJqUOVg2s2LWOB98nceIDzg&s";

//     // Título del PDF
//     doc.setFontSize(18);
//     doc.text('Reporte de Indicadores', 14, 22);
//     doc.setFontSize(15);
//     doc.text('Nombre:' + Ajustador, 14, 30);
//     // Agregar la información de los counts
//     doc.setFontSize(12);
//     doc.addImage(imagenpdf, 'JPEG', 130, 10, 62, 62);
//     doc.text(`Siniestros Cancelados: ${counts.cancelados}`, 14, 40);
//     doc.text(`No Localizados: ${counts.noLocalizados}`, 14, 50);
//     doc.text(`Siniestros: ${counts.siniestros}`, 14, 60);
//     doc.text(`Pólizas Especiales: ${counts.polizasEspeciales}`, 14, 70);
//     doc.text(`Sipacs Entregados: ${counts.SipacsEntregados + counts.SipacsEntregadosPol}`, 14, 80);
//     doc.text(`Sipacs Recibidos: ${counts.SipacsRecibidos + counts.SipacsRecibidosPol}`, 14, 90);
//     doc.text(`Fraudes detectados: ${counts.FraudesConteo}`, 14, 100);
//     doc.text(`Monto Total de Fraudes detectados: $${counts.fraudesMontoTotal}`, 14, 110);

//     const porcentaje_sipac = ((counts.SipacsEntregados + counts.SipacsEntregadosPol)/total_sipacs) * 100;
//     let BonoSipac;
//     if (porcentaje_sipac <= 43) {
//       BonoSipac = 1000;
//     } else {
//       BonoSipac = 0;
//     }
//     // Calcular el total
//     const totalCount = counts.siniestros + counts.polizasEspeciales; // Asume que este es el total de registros
//     const EsperadoFraudes = totalCount * 250;
//     const obtenido_fraudes = counts.fraudesMontoTotal;
//     let IndiceBonoFraudes;
//     let prorcentajeBonoFraudes;
//     let bonototal;
    
//     let BonoFraudes;

//     if (obtenido_fraudes >= EsperadoFraudes) {
//       BonoFraudes = 2500;
//     } else {
//       IndiceBonoFraudes = (obtenido_fraudes/EsperadoFraudes) * 100;
//       prorcentajeBonoFraudes = IndiceBonoFraudes / 100;
//       bonototal = prorcentajeBonoFraudes * 2500;
//       BonoFraudes = bonototal;
//     }
//     let BonoDeArribo;
//     if (porcentajedearriboPrimero > 80) {
//       BonoDeArribo = 1000;
//     } else {
//       BonoDeArribo = 0;
//     }

//     let total = 0;

//     // Lógica para calcular el total basado en el número de registros
//     if (totalCount <= 55) {
//       total = totalCount * 55;
//     } else {
//       total = (55 * 55) + ((totalCount - 55) * 170);
//     }

//     // Agregar la información de los counts y bonos al PDF
//     doc.text('--------------------------------------------------------------------------------------------------------------------------------------------------------------', 1, 115);
//     doc.text(`Total Siniestros y Pólizas (calculado): $ ${total}`, 14, 120);
//     doc.text(`Bono por detección de fraudes: $${BonoFraudes.toFixed(2)}`, 14, 130);
//     doc.text('Bono por responsabilidad de SIPAC:' + BonoSipac.toFixed(), 14, 140);
//     doc.text('Bono de arribo:' + BonoDeArribo, 14, 150);
//     doc.text('--------------------------------------------------------------------------------------------------------------------------------------------------------------', 1, 160);
//     doc.text('Total de incentivos basados en indicadores', 14, 170);
//     doc.text('--------------------------------------------------------------------------------------------------------------------------------------------------------------', 1, 175);

//     // Agregar los datos de siniestros en formato tabla
//     let startY = 185;
//     const columnWidth = [30, 70, 70, 40, 50]; // Ancho de cada columna
//     doc.setFontSize(14);
//     doc.text('Siniestros:', 14, startY);
//     doc.setFontSize(12);
//     startY += 10;
//     doc.text('ID', 14, startY);
//     doc.text('Número', 14 + columnWidth[0], startY);
//     doc.text('Folio', 14 + columnWidth[0] + columnWidth[1], startY);
//     doc.text('Fecha', 14 + columnWidth[0] + columnWidth[1] + columnWidth[2], startY);

//     startY += 10;

//     siniestros.data.forEach((siniestro, index) => {
//       doc.text(siniestro.Id_Siniestro.toString(), 14, startY + (10 * index));
//       doc.text(siniestro.Numero_Siniestro, 14 + columnWidth[0], startY + (10 * index));
//       doc.text(siniestro.Folio_Edua, 14 + columnWidth[0] + columnWidth[1], startY + (10 * index));
//       doc.text(siniestro.Fecha_Ocurrido, 14 + columnWidth[0] + columnWidth[1] + columnWidth[2], startY + (10 * index));
//     });

//     doc.text('--------------------------------------------------------------------------------------------------------------------------------------------------------------', 1, startY + 10);

//     // Agregar los datos de pólizas especiales en formato tabla
//     startY += (10 * siniestros.data.length) + 10;
//     doc.setFontSize(14);
//     doc.text('Pólizas Especiales:', 14, startY);
//     doc.setFontSize(12);
//     startY += 10;
//     doc.text('ID', 14, startY);
//     doc.text('Número', 14 + columnWidth[0], startY);
//     doc.text('Folio', 14 + columnWidth[0] + columnWidth[1], startY);
//     doc.text('Fecha', 14 + columnWidth[0] + columnWidth[1] + columnWidth[2], startY);

//     startY += 10;
//     polizasEspeciales.data.forEach((poliza, index) => {
//       doc.text(poliza.Id_Siniestro.toString(), 14, startY + (10 * index));
//       doc.text(poliza.Numero_Siniestro, 14 + columnWidth[0], startY + (10 * index));
//       doc.text(poliza.Folio_Edua, 14 + columnWidth[0] + columnWidth[1], startY + (10 * index));
//       doc.text(poliza.Fecha_Ocurrido, 14 + columnWidth[0] + columnWidth[2], startY + (10 * index));
//     });

//     doc.save('reporte_indicadores.pdf');
//   };

//   return (
//     <Button type="primary" onClick={generarPDF} style={{ margin: '2rem', backgroundColor: 'red' }}>
//       Generar PDF
//       <Icon icon={filePdfOutline} style={{ fontSize: '24px', color: 'white' }} />
//     </Button>
//   );
// };

// export default GenerarPDF;







import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'; // Importa jspdf-autotable
import { Button } from 'antd';
import { Icon } from '@iconify/react';
import filePdfOutline from '@iconify/icons-mdi/file-pdf-outline'; // Ícono de PDF

const GenerarPDF = ({ counts, Ajustador, total_sipacs, porcentajedearriboPrimero, siniestros, polizasEspeciales }) => {

  const generarPDF = async () => {
    const doc = new jsPDF();
    const imagenpdf = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8aN3lSVnwcW9hSaKjR02EJqUOVg2s2LWOB98nceIDzg&s";

    // Título del PDF
    doc.setFontSize(18);
    doc.text('Reporte de Indicadores', 14, 19);
    doc.setFontSize(15);
    doc.text('Nombre:' + Ajustador, 14, 30);
    // Agregar la información de los counts
    doc.setFontSize(12);
    doc.addImage(imagenpdf, 'JPEG', 110, 8, 100, 100);
    doc.text(`Siniestros Cancelados: ${counts.cancelados}`, 14, 40);
    doc.text(`No Localizados: ${counts.noLocalizados}`, 14, 50);
    doc.text(`Siniestros: ${counts.siniestros}`, 14, 60);
    doc.text(`Pólizas Especiales: ${counts.polizasEspeciales}`, 14, 70);
    doc.text(`Sipacs Entregados: ${counts.SipacsEntregados + counts.SipacsEntregadosPol}`, 14, 80);
    doc.text(`Sipacs Recibidos: ${counts.SipacsRecibidos + counts.SipacsRecibidosPol}`, 14, 90);
    doc.text(`Fraudes detectados: ${counts.FraudesConteo}`, 14, 100);
    doc.text(`Monto Total de Fraudes detectados: $${counts.fraudesMontoTotal}`, 14, 110);

    const porcentaje_sipac = ((counts.SipacsEntregados + counts.SipacsEntregadosPol)/total_sipacs) * 100;
    let BonoSipac;
    if (porcentaje_sipac <= 43) {
      BonoSipac = 1000;
    } else {
      BonoSipac = 0;
    }
    // Calcular el total
    const totalCount = counts.siniestros + counts.polizasEspeciales; // Asume que este es el total de registros
    const EsperadoFraudes = totalCount * 250;
    const obtenido_fraudes = counts.fraudesMontoTotal;
    let IndiceBonoFraudes;
    let prorcentajeBonoFraudes;
    let bonototal;
    
    let BonoFraudes;

    if (obtenido_fraudes >= EsperadoFraudes) {
      BonoFraudes = 2500;
    } else {
      IndiceBonoFraudes = (obtenido_fraudes/EsperadoFraudes) * 100;
      prorcentajeBonoFraudes = IndiceBonoFraudes / 100;
      bonototal = prorcentajeBonoFraudes * 2500;
      BonoFraudes = bonototal;
    }
    let BonoDeArribo;
    if (porcentajedearriboPrimero > 80) {
      BonoDeArribo = 1000;
    } else {
      BonoDeArribo = 0;
    }

    let total = 0;

    // Lógica para calcular el total basado en el número de registros
    if (totalCount <= 55) {
      total = totalCount * 55;
    } else {
      total = (55 * 55) + ((totalCount - 55) * 170);
    }

    const totalPagoBonos = BonoDeArribo + BonoFraudes + BonoSipac + total;
    // Agregar la información de los counts y bonos al PDF
    doc.text('--------------------------------------------------------------------------------------------------------------------------------------------------------------', 1, 115);
    // doc.text(`Total Siniestros y Pólizas (calculado): $ ${total}`, 14, 120);
    doc.text('Total Siniestros y Pólizas (calculado)', 14, 120);
    doc.text('$' + total + '.00', 170, 120);
    // doc.text(`Bono por detección de fraudes: $${BonoFraudes.toFixed(2)}`, 14, 130);
    doc.text('Bono por detección de fraudes', 14, 130);
    doc.text('$' + BonoFraudes.toFixed(2), 170, 130);
    // doc.text('Bono por responsabilidad de SIPAC:' + BonoSipac.toFixed(), 14, 140);
    doc.text('Bono por responsabilidad de SIPAC:', 14, 140);
    doc.text('$' + BonoSipac.toFixed() + '.00', 170, 140);
    doc.text('Bono de arribo:', 14, 150);
    doc.text('$' + BonoDeArribo.toFixed() + '.00', 170, 150);
    doc.text('--------------------------------------------------------------------------------------------------------------------------------------------------------------', 1, 160);
    doc.text('Total de incentivos basados en indicadores: $' , 14, 170);
    doc.text('$' + totalPagoBonos.toFixed(2), 170,170);
    doc.text('--------------------------------------------------------------------------------------------------------------------------------------------------------------', 1, 175);

    // Agregar los datos de siniestros en formato tabla usando autoTable
    let startY = 185;
    autoTable(doc, {
      startY: startY,
      head: [['ID', 'Número', 'Folio', 'Fecha']],
      body: siniestros.data.map(siniestro => [
        siniestro.Id_Siniestro.toString(),
        siniestro.Numero_Siniestro,
        siniestro.Folio_Edua,
        siniestro.Fecha_Ocurrido
      ]),
      theme: 'grid',
      styles: {
        fontSize: 12,
      },
      margin: { top: 10, bottom: 10 },
      didDrawPage: (data) => {
        doc.setFontSize(18);
        doc.text('Siniestros:', 14, 10);
      },
    });

    // Agregar los datos de pólizas especiales en formato tabla usando autoTable
    startY = doc.autoTable.previous.finalY + 10;
    autoTable(doc, {
      startY: startY,
      head: [['ID', 'Número', 'Folio', 'Fecha']],
      body: polizasEspeciales.data.map(poliza => [
        poliza.Id_Siniestro.toString(),
        poliza.Numero_Siniestro,
        poliza.Folio_Edua,
        poliza.Fecha_Ocurrido
      ]),
      theme: 'grid',
      styles: {
        fontSize: 12,
      },
      margin: { top: 10, bottom: 10 },
      didDrawPage: (data) => {
        doc.setFontSize(18);
        doc.text('Pólizas Especiales:', 14, startY);
      },
    });

    doc.text('____________________',155, 12);
    doc.text('Firma de autorización', 160, 18);

    doc.text('____________________',100, 12);
    doc.text('Numero de orden', 110, 18);

    doc.save('reporte_indicadores.pdf');
  };

  return (
    <Button type="primary" onClick={generarPDF} style={{ margin: '2rem', backgroundColor: 'red' }}>
      Generar PDF
      <Icon icon={filePdfOutline} style={{ fontSize: '24px', color: 'white' }} />
    </Button>
  );
};

export default GenerarPDF;

